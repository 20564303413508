<template>
	<div class="mainorder">
		<div class="patientcon">
		<!-- 患者信息 -->
		<div align="left" class="patientLeft"  >
			<div style="font-size: 1.875rem;position: relative;top: -1rem;color:  #2B9BB3;font-weight: bold;">添加患者信息</div>
			<br />
			<br />
			<br />
			<!-- <img src="../assets/签字.png" width="35%" style="position: relative;left: 3.125rem;" /> -->
		</div>
		<div class="patientRIght">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-input v-model="ruleForm.doctorId" v-show="false"></el-input>
				<el-input v-model="ruleForm.productId" v-show="false"></el-input>
				<el-form-item label="患者编号" prop="patientNum">
					<el-input v-model="ruleForm.patientNum" placeholder="请输入患者编号"></el-input>
				</el-form-item>
				<el-form-item prop="patientBirthday" label="出生年月">
					<el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.patientBirthday" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="性别" prop="patientGender">
					<el-radio-group v-model="ruleForm.patientGender">
						<el-radio label="男"></el-radio>
						<el-radio label="女"></el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item style="text-align: center;">
					<el-button class="nextStep" type="primary" @click="submitForm('ruleForm')">下一步</el-button>
					<el-button @click="resetForm('ruleForm')">重&nbsp;&nbsp;&nbsp;置</el-button>
				</el-form-item>
			</el-form>
		</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				ruleForm: {
					doctorId: '',
					productId: 1,
					patientNum: '',
					patientGender: '',
					patientBirthday: '',
					oid:"0"
				},
				rules: {
					patientNum: [{
							required: true,
							message: '请输入患者编号',
							trigger: 'blur'
						}
					],
					patientGender: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					patientBirthday: [{
						// type: 'date',
						required: true,
						message: '请选择出生日期',
						trigger: 'blur',
						
					}]
				}
			}
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$axios.post("client/order/addOrder",JSON.stringify(this.ruleForm),{
							headers:{
								"Content-type":"application/json"
							}
						}).then(res=>{
							console.log(res.data);
							console.log(res.data.data);
							sessionStorage.setItem("oid",res.data.data);
							this.$router.push("ordertwo");
						}).catch(err=>{
							this.$message.error("添加失败");
						})
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		mounted:function(){
			this.$store.commit('setStepIndex',0);
			//获取登录用户的id
			let id=JSON.parse(sessionStorage.getItem("loginInfo")).id;
			console.log(id);
			this.ruleForm.doctorId=id;
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			console.log(oid==undefined);
			if(oid!="undefined"&&oid!=''&&oid!=null){
				console.log(111);
				this.ruleForm.oid=oid;
				//根据oid查询订单信息
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res);
					let orderInfo=res.data.data;
					if(orderInfo.patientNum!=null){
						this.ruleForm.patientNum=orderInfo.patientNum
					}
					if(orderInfo.patientGender!=null){
						this.ruleForm.patientGender=orderInfo.patientGender
					}
					if(orderInfo.patientBirthday!=null){
						this.ruleForm.patientBirthday=orderInfo.patientBirthday
					}
					
				}).catch(err=>{
					console.log(err);
				});
			}
			
		}
	}
</script>

<style scoped="scoped">
	.mainorder{
		/* background-image: linear-gradient(to bottom,white, #9BEDEB, #28D9D5); */
		background-color: whitesmoke;
		height: 100%;
		width: 100%;
	}
	.patientLeft {
		padding-left: 2rem;
		display: 1;
	}

	.patientRIght {
		display: 2;
		text-align: left;
	}

	.el-form {
		margin-top: 3.125rem;
	}

	.mainorder {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	/* .el-form-item {
		display: flex;
		justify-content: space-around;
		margin-top: 2.2rem;
	} */

	.nextStep {
		border: none;
		background-color: #2B9BB3;
	}
	.patientcon{
		/* width: 100%; */
		height: 60%;
		width: 80%;
		background-image: linear-gradient(to bottom,white,lightgray);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 1%;
		border-radius: 0.9375rem;
	}
</style>
